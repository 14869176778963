import "./DiscussionBoardHeader.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BC_Logo from "../../images/BC_Logo.svg";
import Navbar from "../Navbar/Navbar";
import { editSelectedDiscussionQuestion } from "../../utils/api";
import EventActionButtons from "../EventActionButtons/EventActionButtons";
import ExcelExport from "../ExcelExport";
import EditDiscussionQuestionModal from "../EditDiscussionQuestionModal/EditDiscussionModal";
import RenderLogo from "../RenderLogo";

function DiscussionBoardHeader({
  isAdmin,
  currentEvent,
  currentTopic,
  currentDiscussionQuestion,
  currentDiscussionQuestionResponses,
  setLoadAttempt,
}) {
  const [editDiscussionQuestionModal, setEditDiscussionQuestionModal] =
    useState(false);
  const eventtopicdiscussionstatus =
    currentDiscussionQuestion?.eventtopicdiscussionstatus;

  let result =
    currentDiscussionQuestion != null
      ? Object.entries(currentDiscussionQuestion)
      : {};
  const link = {
    text: "Profile",
    path: isAdmin ? "/adminprofile" : "/profile",
    text2: "Event",
    path2: isAdmin
      ? `/adminevent/${currentEvent?._id}`
      : `/event/${currentEvent?._id}`,
    text3: "Topic",
    path3: isAdmin
      ? `/admineventtopic/${currentTopic?._id}`
      : `/eventtopic/${currentTopic?._id}`,
  };

  const eventtopicdiscussionquestion =
    currentDiscussionQuestion?.eventtopicdiscussionquestion;

  const handleEditDiscussionQuestion = (values) => {
    editSelectedDiscussionQuestion({
      topicid: currentTopic._id,
      questionid: currentDiscussionQuestion._id,
      updateddiscussionquestioninfo: values,
    })
      .then(() => {
        setLoadAttempt(false);
        setEditDiscussionQuestionModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="bc__logo-container">
        <Link to={link.path}><img className="bc__logo" src={BC_Logo} alt="BC_logo" /></Link>
        <Navbar link={link} />
      </div>
      <header className="pageheader">
        <div className="pageheader__info-container">
          <div className="pageheader__subheader1-container">
            <div className="pageheader__subheader2-container">
              <p className="card__text-title"> Event </p>
              <p className="card__text-date">{currentEvent?.eventname}</p>
              <p className="card__text-title"> Topic </p>
              <p className="card__text-date">{currentTopic?.topicname}</p>
            </div>
            <div className="pageheader__statusicons-container">
              <RenderLogo
                imageContent={currentEvent?.imageContent}
                classes="pageheader__event-logo"
              />
              {isAdmin && (
                <p className="card__text-status">
                  {eventtopicdiscussionstatus}
                </p>
              )}
            </div>
          </div>
          <div className="pageheader__line"></div>
          <div className="pageheader__info_container">
            <div className="pageheader__info-container-row">
              <div className="pageheader__info-container-col">
                <p className="card__text-title">Discussion Question</p>
                <p className="card__text-date">
                  {eventtopicdiscussionquestion}
                </p>
              </div>
              <div>
                {isAdmin && (
                  <EventActionButtons
                    openModal={() => setEditDiscussionQuestionModal(true)}
                    id={currentDiscussionQuestion._id}
                    state={currentDiscussionQuestion.eventtopicdiscussionstatus}
                    type="discussionQuestion"
                    reload={() => {
                      setLoadAttempt(false);
                    }}
                  >
                    <ExcelExport
                      excelData={result} //discussion question data
                      excelData2={currentDiscussionQuestionResponses}
                      fileName={
                        currentDiscussionQuestion.eventtopicdiscussionquestion
                      }
                    />
                  </EventActionButtons>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </header>
      {editDiscussionQuestionModal && (
        <EditDiscussionQuestionModal
          currentDiscussionQuestion={currentDiscussionQuestion}
          onEditDiscussionQuestion={handleEditDiscussionQuestion}
          onClose={() => {
            setEditDiscussionQuestionModal(false);
          }}
        />
      )}
    </>
  );
}

export default DiscussionBoardHeader;
