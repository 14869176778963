import "./AdminDiscussionBoardPageMain.css";
import ResponseCard from "../ResponseCard/ResponseCard";
import React, { useEffect, useState, useContext } from "react";
import AddResponseFilledIcon from "../../images/add_response-filled_whitebg.svg";
import {
  addDiscussionQuestionResponse,
  getDiscussionQuestionResponses,
  getSelectedDiscussionQuestion,
} from "../../utils/api";
import { useParams } from "react-router-dom";
import CreateNewResponseModal from "../CreateNewResponseModal/CreateNewResponseModal";
import CurrentLoggedInUserContext from "../../contexts/CurrentLoggedInUser";
import { getToken } from "../../utils/auth.js";
import { io } from "socket.io-client";
import useSocket from "../../hooks/useSocket.js";

function AdminDiscussionBoardPageMain({
  currentDiscussionQuestionResponses,
  setLoadAttempt,
  discussionQuestion,
  users,
  handleResponses,
  newResponseEmit,
}) {
  const [createNewResponseModal, setCreateNewResponseModal] = useState(false);
  const params = useParams();
  const { socket, setFetcher } = useSocket(setLoadAttempt);

  const handleCreateNewResponse = () => {
    setCreateNewResponseModal(true);
  };

  const handleAddNewResponse = (values) => {
    addDiscussionQuestionResponse({
      questionid: params.id,
      eventdiscussionquestionresponses: values,
    })
      .then((res) => {
        socket.emit("room", params.id, res, newResponseEmit);
        handleResponses(res);
        setCreateNewResponseModal(false);
        setFetcher(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUser = (response) => users.find((x) => x._id === response.userid[0]);

  return (
    <>
      <main className="main">
        <div className="main__page-section">
          <h2 className="main__headers">Responses</h2>
          <button
            type="button"
            className="button button-stretch"
            onClick={() => {
              handleCreateNewResponse();
            }}
          >
            CREATE NEW RESPONSE
          </button>
          <section className="main__card-section" id="card-section">
            {currentDiscussionQuestionResponses?.map((response) => {
              if (response != null) {
                let user = getUser(response) || {};
                return (
                  <ResponseCard
                    key={response._id}
                    item={response}
                    reload={() => setLoadAttempt(false)}
                    discussionQuestion={discussionQuestion}
                    responseUser={user}
                    socket={socket}
                    setFetcher={setFetcher}
                    newResponseEmit={newResponseEmit}
                  />
                );
              }
            })}
            <div className="button-floating">
              <button
                className="button-icon"
                type="button"
                onClick={handleCreateNewResponse}
              >
                <img
                  className="icon-max"
                  src={AddResponseFilledIcon}
                  alt="add response icon"
                />
              </button>
            </div>
          </section>
        </div>
      </main>
      {createNewResponseModal && (
        <CreateNewResponseModal
          onAddResponse={handleAddNewResponse}
          onClose={() => {
            setCreateNewResponseModal(false);
          }}
          switchToThoughtQuestionsModal={() => {
            setCreateNewResponseModal(false);
          }}
        />
      )}
    </>
  );
}

export default AdminDiscussionBoardPageMain;
