import React, { useState } from "react";
import "./DiscussionQuestionCard.css";
import DeleteIcon from "../../images/DeleteIcon.svg";
import GoToIcon from "../../images/GoToIcon.svg";
import { useNavigate } from "react-router-dom";
import { deleteTopicDiscussionQuestion } from "../../utils/api";
import DeleteDiscussionQuestionModal from "../DeleteDiscussionQuestionModal/DeleteDiscussionQuestionModal";
import { isUserAdmin } from "../../utils/auth";

const DiscussionQuestionCard = ({ questioninfo, topic, reload }) => {
  const isAdmin = isUserAdmin();
  const [deleteDiscussionQuestionModal, setDeleteDiscussionQuestionModal] =
    useState(false);

  const navigate = useNavigate();

  const navigateToDiscussionBoard = (id) => {
    if (isAdmin) {
      navigate(`/admineventtopicdiscussionboard/${id}`);
    } else {
      navigate(`/eventtopicdiscussionboard/${id}`);
    }
  };
  const openDiscussionQuestionDeleteModal = () => {
    setDeleteDiscussionQuestionModal(true);
  };
  const handleDiscussionQuestionDelete = () => {
    deleteTopicDiscussionQuestion({
      topicid: topic._id,
      questionid: questioninfo._id,
    })
      .then(() => {
        setDeleteDiscussionQuestionModal(false);
        reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="card">
        <div className="card__text-container">
          <h3 className="card__text-title">
            {questioninfo?.eventtopicdiscussionquestion}
          </h3>
          <button
            className="button-icon"
            type="button"
            onClick={() => navigateToDiscussionBoard(questioninfo._id)}
          >
            <img className="icon-enlarge" src={GoToIcon} alt="Go to event" />
          </button>
        </div>
        <div className="card__statusicons-container">
          {isAdmin && (
            <p className="card__text-status">
              {questioninfo.eventtopicdiscussionstatus}
            </p>
          )}
          <div className="icon-container">
            {isAdmin && (
              <button
                type="button"
                className="button-icon"
                onClick={openDiscussionQuestionDeleteModal}
              >
                <img className="icon" src={DeleteIcon} alt="delete event" />
              </button>
            )}
          </div>
        </div>
      </div>
      {deleteDiscussionQuestionModal && (
        <DeleteDiscussionQuestionModal
          handleDiscussionQuestionDelete={handleDiscussionQuestionDelete}
          onClose={() => {
            setDeleteDiscussionQuestionModal(false);
          }}
        />
      )}
    </>
  );
};

export default DiscussionQuestionCard;
