import React, { useCallback } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import DownloadIcon from "../images/file_download.svg";
import { useParams } from "react-router-dom";
import { getDiscussionReport } from "../utils/api";

const ExcelExport = ({ excelData, excelData2, fileName, getResponses }) => {
  const params = useParams();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = useCallback(async () => {
    // const flatMap = excelData2.map((x) => ({
    //   ...x,
    //   likes: x.likes.length,
    //   dislikes: x.dislikes.length,
    //   userid: x?.userid[0] || "",
    // }));

    getDiscussionReport(params.id).then(async (res) => {
      const {
        replies,
        responses,
        replyUsers,
        responseUsers,
        eventdiscussionquestionresponses,
        event,
        eventTopic,
        ...rest
      } = res;

      const eventName = event.eventname;
      const eventID = event._id;
      const eventTopicName = eventTopic.topicname;
      const eventTopicID = eventTopic._id;

      const dqdata = {
        ...rest,
        eventTopicName,
        eventTopicID,
        eventName,
        eventID,
      };

      const userdata = [...replyUsers, ...responseUsers];
      console.log(userdata);

      const uniqueUserList = userdata.filter(
        (ele, ind) => ind === userdata.findIndex((elem) => elem._id === ele._id)
      );

      console.log(uniqueUserList);

      const ws0 = XLSX.utils.json_to_sheet([dqdata]);
      const ws1 = XLSX.utils.json_to_sheet(replies);
      const ws2 = XLSX.utils.json_to_sheet(responses);
      const ws3 = XLSX.utils.json_to_sheet(uniqueUserList);
      const wb = {
        Sheets: {
          discussionquestion: ws0,
          responses: ws2,
          replies: ws1,
          users: ws3,
        },
        SheetNames: ["discussionquestion", "responses", "replies", "users"],
      };

      const excelBuffer = await XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = await new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, fileName + fileExtension);
    });
  }, [excelData, excelData2, fileName]);

  return (
    <>
      <button
        type="button"
        className="button-icon button-icon-outline "
        onClick={exportToExcel}
      >
        <img className="icon-enlarge" src={DownloadIcon} alt="delete event" />
      </button>
    </>
  );
};

export default ExcelExport;
